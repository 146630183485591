import { camelCase, keyBy } from 'lodash';
import reframe from 'reframe.js';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import Loading from './Loading';
import Footer from './Footer';
import louisCatoImg from './images/louiscato-min.webp';
import louisCatoImgMobile from './images/louiscato-min-mobile.webp';
import bannerImgTop from './images/banner_top4.webp';
// import bannerImgBottom from './images/banner_bottom.webp';
import mobileBannerImg from './images/mobile_banner3.webp';
import ReactGA from 'react-ga';
import { Outlet, useLocation } from "react-router-dom";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const App = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGallery, setIsLoadingGallery] = useState(true);
  // const [isLoadingInstagram, setIsLoadingInstagram] = useState(true);
  const [isPreloadingImages, setIsPreloadingImages] = useState(true);
  // const [latestInsta, setLatestInsta] = useState(null);
  // const [instagramSuccess, setInstagramSuccess] = useState(true);
  const [socials, setSocials] = useState({ // eslint-disable-line no-unused-vars
    twitter: 'https://twitter.com/realLouisCato',
    instagram: 'https://www.instagram.com/reallouiscato/',
    tiktok: 'https://www.tiktok.com/@reallouiscato',
    facebook: 'https://www.facebook.com/realLouisCato/',
    youtube: 'https://www.youtube.com/@louisism'
  });

  const [width, height] = useWindowSize(); // eslint-disable-line no-unused-vars

  useEffect(() => {
    ReactGA.initialize('UA-81337024-1');
  }, []);

  useEffect(() => {
    const getWordpressData = async function () {
      const handleWordpressError = error => {
          return new Response(JSON.stringify({
              code: 400,
              message: 'Network error',
          }));
      };
      const pagesBySlug = [
        'about',
        'videos',
        'reflections',
        'contact'
      ];
      // env var is also a repo secret in github
      const wordpressResponse = await fetch(`https://api.louiscato.com/wp-json/wp/v2/pages?slug=${pagesBySlug.toString()}`).catch(handleWordpressError);
      if (!wordpressResponse.ok) {
        throw new Error(`Oops, there was a problem. Please try again later.`);
      }
      const wordpressJson = await wordpressResponse.json();
      setData(keyBy(wordpressJson, page => {
        return camelCase(page.slug);
      }));
      setIsLoading(false);
    }
    getWordpressData();
  }, []);

  useEffect(() => {
    const getWordpressData = async function () {
      const handleWordpressError = error => {
          return new Response(JSON.stringify({
              code: 400,
              message: 'Network error',
          }));
      };
      const wordpressResponse = await fetch(`https://api.louiscato.com/wp-json/wp/v2/gallery-item?_embed`).catch(handleWordpressError);
      if (!wordpressResponse.ok) {
        throw new Error(`Oops, there was a problem. Please try again later.`);
      }
      const wordpressJson = await wordpressResponse.json();
      wordpressJson.sort((a, b) => a.acf.sort_order - b.acf.sort_order);
      setGallery(wordpressJson);
      setIsLoadingGallery(false);
    }
    getWordpressData();
  }, []);

  // useEffect(() => {
  //   const getInstagramData = async function () {
  //     const handleInstagramError = error => {
  //         return new Response(JSON.stringify({
  //             code: 400,
  //             error: true,
  //             message: 'Network error',
  //         }));
  //     };
  //     const instagramResponse = await fetch(`${process.env.REACT_APP_IG_URL}IGQWROU0EyV3FHeVpHUmdCLTRVUUJSdjhkaDIxYUtiUElhWnVmTENMUDJDVklTYUVNc1RyaEVUWU55eDlzMlZADZAzNSREx1QXJBYllMejFPMXJYa1ByekFrZAnRXdXFrRmtDTkp0QzdOWmduWlhXd2lYU0JEU3gtejgZD`).catch(handleInstagramError);
  //     const instagramJson = await instagramResponse.json();
  //     console.log(instagramJson);
  //     if (!instagramJson.error) {
  //       const latestVideos = instagramJson.data.filter(ig => {
  //         return ig.media_type === 'VIDEO' && ig.caption.toLowerCase().indexOf('#catocovers') > -1;
  //       });
  //       setLatestInsta(latestVideos.slice(0, 3));
  //       setInstagramSuccess(true);
  //     } else {
  //       setInstagramSuccess(false);
  //     }
  //     setIsLoadingInstagram(false);
  //   }
  //   getInstagramData();
  // }, []);

  useEffect(() => {
    if (!isLoading && !isPreloadingImages && !isLoadingGallery) {
      const script = document.createElement("script");
      script.src = "https://widget.seated.com/app.js";
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isLoading, isPreloadingImages, isLoadingGallery]);

  useEffect(() => {
    if (!isLoading && !isPreloadingImages && !isLoadingGallery) {
      reframe('iframe');
    }
  }, [isLoading, isPreloadingImages, isLoadingGallery]);

  const bgImage = width > 1016 ? louisCatoImg : louisCatoImgMobile;
  const banner = width > 1016 ? bannerImgTop : mobileBannerImg;
  const mainHeaderClasses = location.pathname === '/' ? 'main-header main-header-home' : 'main-header main-header-inner';
  const photoBgClasses = location.pathname === '/' ? 'photo-bg photo-bg-home' : 'photo-bg photo-bg-inner';

  const Site = (
    <div className="App">
      <header className={`${mainHeaderClasses}`}>
        <div className="fade-in">
          <div className={`${photoBgClasses}`} style={{backgroundImage: `url(${bgImage})` }}></div>
          <div className="title-and-menu slide-in-left">
            <h1 className="site-title slide-in-left">Louis Cato</h1>
            <div className="menu slide-in-left">
              <ul>
                <li>
                  <a href="#about">About</a>
                </li>
                <li>
                  <a href="#music">Music</a>
                </li>
                <li>
                  <a href="#videos">Videos</a>
                </li>
                <li className="cato-covers-link">
                  <a href="#catocovers"><span>#</span>catocovers</a>
                </li>
                <li>
                  <a href="#shows">Shows</a>
                </li>
                <li>
                  <a href="#appearances">Appearances</a>
                </li>
                {/* <li>
                  <Link to={`releases`}>Releases</Link>
                </li> */}
                <li>
                  <a href="#contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <Outlet
        context={[
          data,
          gallery,
          socials,
          banner,
          width,
        ]}
      />
      <Footer/>
    </div>
  )

  return !isLoading && !isPreloadingImages && !isLoadingGallery ? Site : <Loading
    isLoading={isLoading}
    // isLoadingInstagram={isLoadingInstagram}
    isPreloadingImages={isPreloadingImages}
    isLoadingGallery={isLoadingGallery}
    imgs={[louisCatoImg, bannerImgTop /*bannerImgBottom*/]}
    onPreloadImg={setIsPreloadingImages}
  />;
}

export default App;
